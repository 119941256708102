import React, { useEffect, useState } from "react"
import _ from "lodash"
import { css } from "emotion"
import { Box } from "rebass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useThemeUI } from "theme-ui"

const Ranking = ({ close }) => {
  const [rankings, setRankings] = useState([])
  const { theme } = useThemeUI()

  useEffect(() => {
    const init = async () => {
      try {
        let response = await fetch(
          "https://brain-api.spinup.media/api/game/rankings",
          {
            method: "get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )

        response = await response.json()

        setRankings(response.rankings)
      } catch (e) {}
    }

    init()
  }, [])

  return (
    <>
      <Box
        className={css`
          position: fixed;
          display: flex;
          flex-direction: column;
          align-items: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 20px;
          background-color: #1a1818;
          color: #fff;
          font-family: monospace;
          z-index: 222;
          overflow: auto;
          overflow-y: scroll;
        `}
      >
        <Box
          sx={{
            position: "fixed",
            top: "20px",
            right: "40px",
            fontSize: 3,
            zIndex: 111,
            cursor: "pointer",
          }}
          onClick={() => close()}
        >
          <FontAwesomeIcon icon={faTimes} color="#fff" />
        </Box>

        {!!rankings.length && (
          <table
            className={css`
              td,
              th {
                padding: 3px 10px;
                text-align: left;
                font-size: 12px;
                @media (min-width: ${theme.breakpoints[0]}) {
                  font-size: 16px;
                }
              }
            `}
          >
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Company</th>
                <th scope="col">Win time (seconds)</th>
              </tr>
            </thead>

            <tbody>
              {_.take(rankings, 200).map((rank, i) => (
                <tr key={i}>
                  <td>{i + 1}°</td>
                  <td>{rank.name}</td>
                  <td>{rank.company}</td>
                  <td>{(rank.score / 1000).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Box>
    </>
  )
}

export default Ranking
